import { get, post } from '@/utils/apiBase.js'

// ****************** 新接口 ****************** 

// 新接口-众筹基本信息
const getProjectBasicInfo = option => {
  return get('/apis/product/pro_basic_info', option)
}
// 新接口-众筹基本信息实时数据接口
const getProjectBasicInfoRT = option => {
  return get('/apis/product/pro_basic_info_rt', option)
}
// 新接口-获取众筹基本信息关联信息
const getProjectBasicRelateInfo = option => {
  return get('/apis/product/pro_basic_relate_info', option)
}
// 新接口-获取众筹基本信息动态接口
const getProjectBasicDynamicInfo = option => {
  return get('/apis/product/pro_basic_dynamic', option)
}
// 新接口-获取众筹富文本信息
const getProjectBasicContent = option => {
  return get('/apis/product/pro_basic_content', option)
}

// 获取3C证书信息
const getQueryUserLicenseInfo3c = option => {
  return get('/apis/mdlicense/query_user_license_info_3c', option)
}

// 获取支持的人列表
const getBackUserList = option => {
  // return post('/apis/project_zc/back_user_list', option)
  return get('/apis/project/get_pro_backer_list', option)
}
// 获取众筹目标
const getProjectGoals = option => {
  return get('/product/goals', option)
}
// 获取项目成员
const getMemberList = option => {
  return get('/v45/product/member_list', option)
}
// 获取发起人项目履历
const getHistoryProjectList = option => {
  return get('/apis/user_center/history_product_list', option)
}
// 获取回报档列表
const getRewardList = option => {
  return get('/apis/project_zc/reward_list', option)
}
// 获取回报档内容详情
const refreshRewards = option => {
  return get('/product/rewards/refresh', option)
}
// 获取常见问题列表
const getQuestionlist = option => {
  return get('/apis/product/common_question/list', option)
}
// 看好项目 / 取消看好
const favorProject = option => {
  return post('/apis/favor/batch_operation_favor', option)
}
// 获取一起筹数量
const getTeamfundTotal = option => {
  return get('/apis/teamfund/teamfund_total', option)
}
// 检测是否关联回报档
const checkReward = option => {
  return get('/apis/mdorder/check_reward', option)
}
// H5项目预览
const previewProject = option => {
  return get('/apis/project/cp/preview_project', option)
}
// 项目下活动列表
const getProphetProList = option => {
  return get('/apis/activities/prophet/pro/list', option)
}
// 点击分享
const ClickShare = option => {
  return post('/apis/project/click_share', option)
}
// 小红书种草活动-中奖信息
const getRedNotePrizeInfo = option => {
  return get('/apis/project/rednote_winner_list', option)
}

// 即将众筹项目列表
const getSubscribeList = option => {
  return get('/apis/project/pro_coming_soon_list', option)
}
export default {
  getBackUserList,
  getProjectGoals,
  getMemberList,
  getHistoryProjectList,
  getRewardList,
  refreshRewards,
  getQuestionlist,
  favorProject,
  getTeamfundTotal,
  checkReward,
  previewProject,
  getProphetProList,
  ClickShare,
  getRedNotePrizeInfo,
  getProjectBasicInfo,
  getProjectBasicInfoRT,
  getProjectBasicRelateInfo,
  getProjectBasicDynamicInfo,
  getProjectBasicContent,
  getQueryUserLicenseInfo3c,
  getSubscribeList
}
